<template>
  <section
    v-if="order"
    class="invoice-preview-wrapper"
  >
    <b-modal
      v-model="showComment"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      title="Ajouter un commentaire"
    >
      <b-form-textarea
        id="event-description"
        v-model="comment"
        class="mb-1"
      />
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="addComment"
        >
          <span class="align-middle">Enregistrer</span>
        </b-button>
      </div>
    </b-modal>
    <b-row
      v-if="order"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <a :href="`/commande/${order.number}`">
                    <b-button
                      class="text-warning"
                    >
                      <small>Back to order</small>
                    </b-button>
                  </a>
                </div>
                <h6 class="mb-1">
                  Livraison:
                </h6>
                <p class="card-text mb-25">
                  {{ order.shipping.address_1 }}
                </p>
                <p
                  v-if="order.shipping.addres_2"
                  class="card-text mb-25"
                >
                  {{ order.shipping.addres_2 }}
                </p>
                <p class="card-text mb-25">
                  {{ order.shipping.postcode }} {{ order.shipping.city }}, {{ order.shipping.state }} {{ order.shipping.country }}
                </p>
                <p
                  v-if="order.meta_data.find(m => m.key === '_shipping_phone')"
                  class="card-text mb-25"
                >
                  {{ order.meta_data.find(m => m.key === '_shipping_phone').value }}
                </p>
                <p
                  v-if="order.meta_data.find(m => m.key === '_shipping_etage')"
                  class="card-text mb-25"
                >
                  <strong><u>Etage: </u></strong>{{ order.meta_data.find(m => m.key === '_shipping_etage').value }}
                </p>
                <p
                  v-if="order.meta_data.find(m => m.key === '_shipping_ascenseur')"
                  class="card-text mb-25"
                >
                  <strong><u>Ascenseur: </u></strong> {{ order.meta_data.find(m => m.key === '_shipping_ascenseur').value }}
                </p>
                <p
                  v-if="order.meta_data.find(m => m.key === '_shipping_refer_to')"
                  class="card-text mb-25"
                >
                  <strong><u>Connu par: </u></strong> {{ order.meta_data.find(m => m.key === '_shipping_refer_to').value }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <div class="d-flex align-item-center">
                  <h4 class="invoice-title">
                    <span><strong><u>Passée le:</u></strong> {{ $moment(order.date_created).locale('fr').format('DD MMMM YYYY') }} à {{ $moment(order.date_created).locale('fr').format('hh:mm') }}</span>
                  </h4>
                </div>
                <h6 class="mb-2">
                  Facturation:
                </h6>
                <h6 class="mb-25">
                  {{ order.billing.first_name }} {{ order.billing.last_name }}
                </h6>
                <p
                  v-if="order.billing.company"
                  class="card-text mb-25"
                >
                  {{ order.billing.company }}
                </p>
                <p
                  v-if="order.billing.email"
                  class="card-text mb-25"
                >
                  {{ order.billing.email }}
                </p>
                <p class="card-text mb-25">
                  {{ order.billing.address_1 }}
                </p>
                <p
                  v-if="order.billing.address_2"
                  class="card-text mb-25"
                >
                  {{ order.billing.address_2 }}
                </p>
                <p class="card-text mb-25">
                  {{ order.billing.postcode }} {{ order.billing.city }}, {{ order.billing.state }} {{ order.billing.country }}
                </p>
              </div>
              <div class="mt-md-0 mt-2">
                <div class="d-flex">
                  <h4 class="invoice-title mr-2">
                    <span class="invoice-number">#{{ order.number }}</span>
                  </h4>
                </div>
                <div>
                  <h6 class="mb-2">
                    Détail du paiement:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total:
                        </td>
                        <td><span class="font-weight-bold">{{ order.total }}€</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Type:
                        </td>
                        <td>{{ order.payment_method }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Paiement:
                        </td>
                        <td>{{ order.payment_method_title }}</td>
                      </tr>
                      <tr v-if="order.date_paid">
                        <td class="pr-1">
                          Date de paiement:
                        </td>
                        <td>{{ $moment(order.date_paid).format('DD-MM-YYYY') }}</td>
                      </tr>
                      <tr v-if="order.meta_data.find(m => m.key === '_shipping_fiscal_code') && order.meta_data.find(m => m.key === '_shipping_fiscal_code').value !== ''">
                        <td class="pr-1">
                          Code Fiscal:
                        </td>
                        <td>{{ order.meta_data.find(m => m.key === '_shipping_fiscal_code').value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-card-body>

          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <b-col
                cols="4"
                md="4"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div class="sidebar-left chat-application">
                  <div class="sidebar">

                    <!-- Logged In User Profile Sidebar -->
                    <!-- <user-profile-sidebar
                        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
                        :profile-user-data="profileUserData"
                        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
                      /> -->

                    <!-- Sidebar Content -->
                    <div
                      class="sidebar-content"
                      :class="{'show': true}"
                    >
                      <vue-perfect-scrollbar
                        :settings="perfectScrollbarSettings"
                        class="chat-user-list-wrapper list-group scroll-area"
                      >

                        <!-- Chats Title -->
                        <h4 class="chat-list-title">
                          Claims
                        </h4>

                        <!-- Chats -->
                        <ul class="chat-users-list chat-list media-list">
                          <chat-contact
                            v-for="(claim, key) in claims"
                            :key="key"
                            :user="claim"
                            tag="li"
                            :class="{'active': claimSelect && claim._id === claimSelect._id}"
                            :is-chat-contact="false"
                            @click="selectChat(claim)"
                          />
                        </ul>
                      </vue-perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- Col: Sales Persion -->
              <b-col
                cols="8"
                md="8"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <section class="chat-app-window">
                  <div
                    class="active-chat"
                  >

                    <!-- User Chat Area -->
                    <vue-perfect-scrollbar
                      ref="refChatLogPS"
                      :settings="perfectScrollbarSettings"
                      class="user-chats scroll-area"
                      style="height: 400px;"
                    >
                      <chat-log
                        v-if="chat"
                        :chat-data="chat"
                        :claim-id="claimSelect._id"
                        @update="getClaims"
                      />
                    </vue-perfect-scrollbar>

                    <!-- Message Input -->
                    <b-form
                      class="chat-app-form"
                      @submit.prevent="sendMessage"
                    >
                      <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-input
                          v-model="chatInputMessage"
                          placeholder="Enter your message"
                        />
                      </b-input-group>
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Send
                      </b-button>
                    </b-form>
                  </div>
                </section>
              </b-col>
            </b-row>
          </b-card-body>
          <b-col>
            <div v-if="claimSelect">
              <h4 class="chat-list-title">
                Details
              </h4>
              <b-row class="mb-2">
                <b-col
                  cols="6"
                >
                  <b-form-group
                    label="Description Type"
                  >
                    <v-select
                      v-model="claimSelect.descriptionType"
                      :options="descriptionOptions"
                      transition=""
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                >
                  <b-form-group
                    label="Sub Description Type"
                  >
                    <v-select
                      v-model="claimSelect.subDescriptionType"
                      :options="subDescriptionOptions.find(d => d.key === claimSelect.descriptionType).options"
                      transition=""
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                >
                  <b-form-group
                    label="Status Customer Support"
                  >
                    <v-select
                      v-model="claimSelect.statusType"
                      :options="statusOptions.find(d => d.key === claimSelect.type).options"
                      transition=""
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="claimSelect.descriptionType && claimSelect.subDescriptionType"
                  cols="6"
                >
                  <b-form-group
                    label="Status OPS"
                  >
                    <v-select
                      v-model="claimSelect.statusOpsType"
                      :options="statusOptions.find(d => d.key === claimSelect.type).optionsOps"
                      transition=""
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="claimSelect.descriptionType && claimSelect.subDescriptionType && claimSelect.statusType && claimSelect.statusOpsType"
                  cols="6"
                >
                  <b-form-group
                    label="Contact Type"
                  >
                    <v-select
                      v-model="claimSelect.contactType"
                      :options="['Phone Number', 'Email', 'Chat Link', 'Retail', 'Other']"
                      transition=""
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="claimSelect.descriptionType && claimSelect.subDescriptionType && claimSelect.statusType && claimSelect.statusOpsType && claimSelect.contactType"
                  cols="6"
                >
                  <b-form-group
                    label="Contact Description"
                  >
                    <b-form-input v-model="claimSelect.contactDescription" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  cols="6"
                >
                  <b-form-group
                    label="Date d'échéance"
                  >
                    <flat-pickr
                      v-model="claimSelect.dueDate"
                      class="form-control"
                      placeholder="YYYY-MM-DD"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                >
                  <b-form-group
                    label="Closed Date"
                  >
                    <flat-pickr
                      v-model="claimSelect.endDate"
                      class="form-control"
                      placeholder="YYYY-MM-DD"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                >
                  <b-form-group
                    label="Other Assignees"
                  >
                    <v-select
                      v-model="claimSelect._otherAssignees"
                      :options="assigneeOptions"
                      multiple
                      label="fullName"
                      input-id="_id"
                    >
                      <template #option="{ firstName, lastName }">
                        <span class="ml-50 d-inline-block align-middle"> {{ firstName }} {{ lastName }}</span>
                      </template>
                      <template #selected-option="{ firstName, lastName }">
                        <span class="ml-50 d-inline-block align-middle"> {{ firstName }}  {{ lastName }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  cols="6"
                >
                  <b-button variant="primary" @click="updateClaim()">
                    <!-- <feather-icon icon="AlertOctagonIcon" class="mr-50" /> -->
                    <span class="align-middle">Update</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <!-- Invoice Description: Total -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BCardBody, BButton, VBToggle, BFormTextarea,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import logistics from '@utils/logistic.json'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from './chat/ChatLog.vue'
import ChatContact from './chat/ChatContact.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BForm,
    BFormInput,
    ChatLog,
    BInputGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    ChatContact,
    BFormGroup,
    // BTableLite,
    // BCardText,
    BButton,
    BFormTextarea,
    VuePerfectScrollbar,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      contacts: [
        {
          id: 7,
          fullName: 'Miguel Guelff',
          role: 'Special educational needs teacher',
          about:
        'Biscuit powder oat cake donut brownie ice cream I love soufflé. I love tootsie roll I love powder tootsie roll.',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/7.png'),
          status: 'online',
        },
        {
          id: 8,
          fullName: 'Mauro Elenbaas',
          role: 'Advertising copywriter',
          about:
        'Bear claw ice cream lollipop gingerbread carrot cake. Brownie gummi bears chocolate muffin croissant jelly I love marzipan wafer.',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/12.png'),
          status: 'away',
        },
        {
          id: 9,
          fullName: 'Bridgett Omohundro',
          role: 'Designer, television/film set',
          about:
        'Gummies gummi bears I love candy icing apple pie I love marzipan bear claw. I love tart biscuit I love candy canes pudding chupa chups liquorice croissant.',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/9.png'),
          status: 'offline',
        },
        {
          id: 10,
          fullName: 'Zenia Jacobs',
          role: 'Building surveyor',
          about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10.png'),
          status: 'away',
        },
        {
          id: 10,
          fullName: 'Zenia Jacobs',
          role: 'Building surveyor',
          about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10.png'),
          status: 'away',
        },
        {
          id: 10,
          fullName: 'Zenia Jacobs',
          role: 'Building surveyor',
          about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10.png'),
          status: 'away',
        },
        {
          id: 10,
          fullName: 'Zenia Jacobs',
          role: 'Building surveyor',
          about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10.png'),
          status: 'away',
        },
        {
          id: 10,
          fullName: 'Zenia Jacobs',
          role: 'Building surveyor',
          about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10.png'),
          status: 'away',
        },
      ],
      chatInputMessage: '',
      logistics,
      logistic: null,
      order: null,
      claims: null,
      claimSelect: null,
      showComment: false,
      comment: '',
      assigneeOptions: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      chat: null,

      claimsOptions: ['Forwarder'],
      subClaimOptions: [
        {
          key: 'Forwarder',
          options: ['Agediss', 'BRT', 'Colisland', 'GLS', 'UPS Italy', 'UPS France', 'Geodiss'],
        },
      ],
      descriptionOptions: ['IT', 'Tracking', 'Damaged', 'Delay', 'Additional Services', 'Wrong Product', 'Return', 'Bribes'],
      subDescriptionOptions: [
        {
          key: 'IT',
          options: ['Product not dispatched', 'Order not dispatched'],
        },
        {
          key: 'Tracking',
          options: ['Wrong tracking number', 'No tracking number', 'No update', 'Lost parcel', 'Not delivered', 'Return to shipper'],
        },
        {
          key: 'Damaged',
          options: ['Damaged packaging', 'Damaged item', 'Missing item'],
        },
        {
          key: 'Delay',
          options: ['No delivery appointment', 'Missed delivery appointment', 'Delayed delivery'],
        },
        {
          key: 'Additional Services',
          options: ['Floor delivery', 'Assembly', 'Old Item Collection'],
        },
        {
          key: 'Wrong Product',
          options: ['Wrong size', 'Wrong colour', 'Wrong quantity'],
        },
        {
          key: 'Return',
          options: ['No return appointment', 'Missed return appointment', 'Delayed return', 'Refused return'],
        },
        {
          key: 'Bribes',
          options: ['Bribes'],
        },
      ],
      statusOptions: [
        {
          key: 'Forwarder',
          options: ['First check', 'IT support', 'Waiting for forwarder feedback', 'OPS support', 'CS ongoing', 'Waiting for customer feedback', 'Check refund customer', 'Last check', 'Closed'],
          optionsOps: ['IT support', 'Waiting for forwarder feedback', 'Check refund forwarder', 'Last check', 'Closed'],
        },
      ],
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  async mounted() {
    this.get()
    this.getOrder()
    const { users } = await this.$http.get('/user')
    this.assigneeOptions = users.map(u => ({ ...u, fullName: `${u.firstName} ${u.lastName}` }))
  },
  methods: {
    ...mapActions('admin', ['get']),
    selectChat(claim) {
      this.chat = claim.comments
      this.claimSelect = claim
    },
    async updateClaim() {
      try {
        await this.$http.put(`/admin/claim/${this.claimSelect._id}`, this.claimSelect)
        await this.getClaims()
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Saved.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
    async sendMessage() {
      try {
        const chat = {
          message: this.chatInputMessage,
          time: this.$moment(),
          _user: this.me._id,
        }
        await this.$http.post(`/admin/claim/${this.claimSelect._id}/message`, chat)
        await this.getClaims()
        this.chatInputMessage = ''
      } catch (err) {
        console.log(err)
      }
    },
    // eslint-disable-next-line no-irregular-whitespace
    async getClaims() {
      try {
        const { claims } = await this.$http.get(`/admin/order/${this.$route.params.id}/claims`)
        this.claims = claims
        if (!this.claimSelect && this.$route.query?.id) {
          this.claimSelect = this.claims.find(c => c._id === this.$route.query?.id)
        } else if (!this.claimSelect) {
          this.claimSelect = { ...this.claims[0] }
        } else {
          this.claimSelect = this.claims.find(c => c._id === this.claimSelect._id)
        }
        this.selectChat(this.claimSelect)
      } catch (err) {
        console.log(err)
      }
    },
    async getOrder() {
      try {
        const { order } = await this.$http.get(`/admin/order/${this.$route.params.id}/dashboard`)
        this.order = order
        this.order = order
        this.order.itemsLength = order?.line_items?.length
        await this.getClaims()
      } catch (err) {
        console.log(err)
      }
    },
    async printInvoice() {
      try {
        this.order.logisticChoice = this.logistic
        this.order.total = this.order.line_items.map(r => Number(r.total) + Number(r.total_tax)).reduce((a, b) => a + b, 0).toFixed(2)
        this.order.total_tax = this.order.line_items.map(r => Number(r.total_tax)).reduce((a, b) => a + b, 0).toFixed(2)
        this.order.shipping_total = this.order.shipping_lines.map(l => Number(l.total)).reduce((a, b) => a + b, 0).toFixed(2)
        const { link } = await this.$http.post('/admin/invoice-special/', { order: this.order })
        window.open(link)
      } catch (err) {
        console.log(err)
      }
      // window.print()
    },
    async printExportation() {
      try {
        const { link } = await this.$http.post('/admin/invoice-special/exportation/', { order: this.order })
        window.open(link)
      } catch (err) {
        console.log(err)
      }
      // window.print()
    },
    async deleteComment(key) {
      try {
        const comments = this.order.sav.comments.filter((c, i) => i !== key)
        await this.$http.post(`/admin/order/${this.$route.params.id}/deleteComment`, { comments })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Le commentaire a bien été ajouté.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showComment = false
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    async addComment() {
      try {
        await this.$http.put(`/admin/order/${this.$route.params.id}/addComment`, { text: this.comment })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Le commentaire a bien été ajouté.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showComment = false
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    async sav(status) {
      try {
        await this.$http.put(`/admin/order/${this.$route.params.id}/sav`, { status })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Mise à jour réussie.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    resolveStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'sended') return 'secondary'
      if (status === 'failed') return 'warning'
      if (['cancelled', 'on-hold'].includes(status)) return 'danger'
      return 'primary'
    },
    resolveStatusTranslation(status) {
      switch (status) {
        case 'processing':
          return 'En fabrication'
        case 'pending':
          return 'En attente de paiement'
        case 'on-hold':
          return 'En attente'
        case 'sended':
        case 'completed':
          return 'Expédiée'
        case 'failed':
          return 'Echouée'
        case 'cancelled':
          return 'Annulée'
        case 'refunded':
          return 'Remboursée'
        case 'rembourse-partiel':
          return 'Remboursée partiellement'
        default:
          return status
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  .trash {
    display: none;
  }

  .content-header {
    display: none;
  }

  .demo-inline-spacing {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
