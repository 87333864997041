<template>
  <div>
    <b-modal
      v-model="showModalUpdate"
      ok-only
      ok-title="Accept"
      centered
      size="md"
      :hide-footer="true"
      title="Update Message"
      lazy
      static
    >
      <b-row v-if="message">
        <b-col md="6">
          <div>
            <b-form-input
              v-model="message.message"
              style="width: 100%"
            />
          </div>
        </b-col>
        <b-col md="6">
          <b-button
            variant="primary"
            class="btn-cart mt-2"
            :disabled="!message"
            :loading="loading"
            @click="updateMessage"
          >
            <span>SAVE</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <section class="chat-app-window">
      <div class="chat-app-window chats">
        <div
          v-for="(msgGrp, index) in chatData"
          :key="msgGrp.senderId+String(index)"
          class="chat"
          :class="{'chat-left': msgGrp._user._id !== me._id}"
        >
          <div class="chat-avatar">
            <b-avatar
              size="36"
              class="avatar-border-2 box-shadow-1"
              :variant="msgGrp._user._id !== me._id ? 'secondary' : 'primary'"
              :text="`${msgGrp._user.firstName[0]}.${msgGrp._user.lastName[0]}.`"
            />
          </div>
          <div class="chat-body">
            <div
              class="chat-content"
            >
              <p>{{ msgGrp.message }}</p>
              <feather-icon
                v-if="msgGrp._user._id === me._id"
                icon="TrashIcon"
                @click="deleteMessage(msgGrp)"
              />
              <feather-icon
                v-if="msgGrp._user._id === me._id"
                icon="EditIcon"
                @click="openModal(msgGrp)"
              />
              <br v-if="msgGrp._user._id === me._id">
              <small class="float-right mb-25 chat-time">{{ $moment(new Date(msgGrp.time)).format('HH:mm - DD/MM/YYYY') }} {{ msgGrp.isEdited ? ' - Edited' : '' }}</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  BAvatar, BModal, BFormInput, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BModal,
    BFormInput,
    BButton,
  },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
    claimId: {
      type: String,
      required: true,
    },
    // profileUserAvatar: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      message: null,
      showModalUpdate: false,
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: 1,
        avatar: 'cat',
      }

      let chatLog = []
      console.log(props.chatData)
      if (props.chatData) {
        chatLog = props.chatData
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              time: msg.time,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        // profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
      formatDateToMonthShort,
    }
  },
  methods: {
    openModal(msg) {
      this.message = msg
      this.showModalUpdate = true
    },
    async deleteMessage(msg) {
      this.message = msg
      await this.updateMessage(true)
    },
    async updateMessage(isDelete) {
      try {
        await this.$http.post(`/admin/claim/${this.claimId}/update-message`, { message: this.message, isDelete })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Bien enregistré.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.message = null
        this.showModalUpdate = false
        this.$emit('update')
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
